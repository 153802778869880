/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import Carousel from 'react-elastic-carousel';
import Slider from '../../../shared/UIKit/SliderWs/index';

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Workspace() {
  /* ******************************** CONSTANTS ******************************* */
  // carousel props
  const CrouselSettings = {
    showArrows: false,
    pagination: false,
    breakPoints: [
      { Width: 500, itemsToShow: 1, itemPadding: [0, 10] },
      { width: 850, itemsToShow: 1, itemPadding: [0, 20] },
    ],
  };
  /* ********************************** HOOKS ********************************* */
  // Localization:
  const { t } = useTranslation();
  const { ListWS } = t('workSpace', { returnObjects: true });
  /* ******************************** RENDER HELPERS ******************************* */
  const Sliders = ListWS.map(
    ({ id, SlideTitle, SlideSubTitle, Descreption, img }) => (
      <Slider
        id={id}
        SlideTitle={SlideTitle}
        SlideSubTitle={SlideSubTitle}
        Descreption={Descreption}
        img={img}
      />
    ),
  );
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="workspace" fluid>
      <div className="container">
        <Carousel {...CrouselSettings}>{Sliders}</Carousel>
      </div>
    </Container>
  );
}

Workspace.propTypes = {};

Workspace.defaultProps = {};
export default Workspace;
