/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents

// Local UI components

// Style
import './index.scss';
// asstes
import ElifLogo from '../../../images/slogan/FrameElife.svg';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Slogan() {
  /* ******************************** CONSTANTS ******************************* */

  /* ********************************** HOOKS ********************************* */
  // Localisation
  const { t } = useTranslation();
  const { Title, SloganDesc, cities } = t('Slogan', {
    returnObjects: true,
  });

  /* ***************************** RENDER HELPERS ***************************** */
  function CitiesList() {
    return (
      <ul>
        {cities.map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ul>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <section className="slogan-program-elif">
      <img src={ElifLogo} alt="logo-elif" />
      <h1>{Title}</h1>
      <p>{SloganDesc}</p>
      <CitiesList />
    </section>
  );
}

Slogan.propTypes = {};

Slogan.defaultProps = {};

export default Slogan;
