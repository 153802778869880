/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// Assets
import Arrow from '../../../images/Elife-section/Arrow.svg';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function VisitationSection({ namePage, navigation }) {
  /* ******************************** CONSTANTS ******************************* */

  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const { visitText } = t('shared', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */

  /* ***************************** RENDER HELPERS ***************************** */

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="visitation-section" fluid>
      <Col onClick={() => navigate(navigation)}>
        <Row justify="center" align="center">
          <h2>{`${visitText} ${namePage}`}</h2>
        </Row>
        <Row justify="center" align="center">
          <img src={Arrow} alt="Visit" />
        </Row>
      </Col>
    </Container>
  );
}

VisitationSection.propTypes = {
  namePage: PropTypes.string.isRequired,
  navigation: PropTypes.string.isRequired,
};

export default VisitationSection;
