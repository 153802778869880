/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import Image from '../../../shared/Image';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function AboutElife() {
  // localisation
  const { t } = useTranslation();
  const { title, content, extraContent } = t('aboutElife', {
    returnObjects: true,
  });
  /* ----------------------------- RENDER HELPERS ----------------------------- */
  function ParagraphList(list) {
    return list.map(({ id, text }) => (
      <p key={id} className="about-elife__paragraph__item">
        {text}
      </p>
    ));
  }
  const ParagraphsMap = content.map(({ id, paragraph, list = null }) => (
    <div key={id} className="about-elife-content">
      <p className="about-elife__paragraph">{paragraph}</p>
      {list && ParagraphList(list)}
    </div>
  ));
  /* ******************************** RENDERING ******************************* */

  return (
    <section className="about-elife">
      <div className="container">
        <SubTitle>{title}</SubTitle>
        <Container fluid>
          <Row justify="center" align="start">
            <Col lg={6}>
              <div className="about-elife-col">{ParagraphsMap}</div>
            </Col>
            <Col lg={6}>
              <div className="about-elife-col">
                <div className="about-elife-img-container">
                  <Image src="about-elife-img.jpg" alt="elife image" />
                </div>
                <div className="about-elife-extra-content">
                  <p className="about-elife__paragraph">{extraContent}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

AboutElife.propTypes = {};

AboutElife.defaultProps = {};

export default AboutElife;
