/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes from 'prop-types';

// UI lib comoponents

// Local UI components
import Image from '../../Image';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function SliderWs({ id, SlideTitle, SlideSubTitle, Descreption, img }) {
  /* ******************************** HOOKS******************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="slider-ws" key={id}>
      <div className="slider-ws__Image-container">
        <Image src={img} alt="programe-elife" />
      </div>
      <div className="slider-ws__Content">
        <h2>{SlideTitle}</h2>
        <div className="slider-ws__Content__description">
          <h3>{SlideSubTitle}</h3>
          <p>{Descreption}</p>
        </div>
      </div>
    </div>
  );
}

SliderWs.propTypes = {
  id: PropTypes.string.isRequired,
  SlideTitle: PropTypes.string.isRequired,
  SlideSubTitle: PropTypes.string.isRequired,
  Descreption: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

SliderWs.defaultProps = {};

export default SliderWs;
