/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Slogan from '../page-components/ProgramElife/Slogan';
import Workspace from '../page-components/ProgramElife/Workspace';
import VisitationSection from '../shared/UIKit/VisitationSection';

// Style
import '../page-styles/ProgramElife.scss';

import AboutElife from '../page-components/ProgramElife/AboutElife';
import TechnoCenters from '../page-components/ProgramElife/TechnoCenters';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function ProgramElife() {
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Program elife" />
      <div className="program-elife">
        <Slogan />
        <AboutElife />
        <Workspace />
        <TechnoCenters />
        <VisitationSection
          namePage="Elife"
          navigation="https://elife.fondationtunisie.org"
        />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ProgramElife", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProgramElife;
